<template>
  <v-lazy :min-height="avatarSize" :options="{ threshold: 0.1 }" transition="fade-transition">
    <v-chip
      v-bind="$attrs"
      class="pl-0"
      :color="!variant || variant === 'tonal' ? type.toLowerCase() : undefined"
      :variant="variant ?? 'tonal'"
      :density="density ?? 'comfortable'"
    >
      <template #prepend>
        <v-avatar :size="avatarSize" class="mr-2" :color="type.toLowerCase()">
          <v-icon size="small" :icon="type ? $t(`organizationIcon.${type}`) : 'question_mark'" />
        </v-avatar>
      </template>

      <span class="text-high-emphasis">{{ type ? $t(`admin.organizations.type.${type}`) : $t("unknown") }}</span>
    </v-chip>
  </v-lazy>
</template>

<script setup lang="ts">
import type { OrganizationType } from "~~/stores/organization.store"

const props = defineProps<{
  type: OrganizationType
  density?: "comfortable" | "compact" | "default"
  variant?: "tonal" | "text"
}>()
const { type, density } = toRefs(props)

const avatarSize = computed(() => {
  if (density?.value === "compact") return "1.3rem"
  if (density?.value === "comfortable" || !density.value) return "1.5rem"
  return "2rem"
})
</script>
